@media (max-width: 1200px) {
  header{
    .header-address{
      font-size: 2rem;
      max-width: 90px;
      text-align: center;
      z-index: 100;
    }
    .header-body{
      a.header-logo{
        img{
          max-height: 60px;
        }
        span{
          font-size: 2rem;
        }
      }
      .header-list{
        li{
          a{
            &.header-link{
              padding: 33px 7px;
            }
          }
        }
      }
    }
  }
  .wrapper-width {
    max-width: 970px;
  }
  .top-social{
    display: flex;
    flex-direction: row;
    a{
      img{
        max-width: 35px;
      }
    }
  }
}

@media (max-width: 1024px) {
  header{
    .header-body{
      .header-list{
        li{
          a.header-link{
            padding: 33px 0px;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .wrapper-width {
    max-width: 750px;
  }
  .agro{
    .agro-body{
      .agro-text-block.right-side{
        flex: 0 1 58%;
      }
      .agro-image-block.left-side{
        flex: 0 1 30%;
      }
    }
  }
  header{
    .header-body{
      .header-list{
        li{
          a.header-link{
            padding-left: 10px;
            padding-right: 10px;
            font-size: 1.3rem ;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .agro{
    .agro-body{
      .agro-text-block.right-side{
        flex: 0 1 100%;
      }
      .agro-image-block.left-side{
        flex: 0 1 100%;
      }
    }
  }
  body {
    &.lock {
      overflow: hidden;
    }

    .wrapper-width {
      max-width: none;
    }
  }
  .two-block{
    flex: 1 0 100%;
    flex-direction: row;
    margin-top: 15px;
    padding: 0;
    max-width: 100%;
  }
  .three-block {
    flex: 1 0 100%;
    flex-direction: row;
    margin-top: 15px;
    padding: 0;
  }

  .four-block {
    flex: 1 0 50%;
    flex-direction: row;
    margin-top: 15px;
    padding: 0 10px;
  }

  .footer-nav {
    display: none;
  }

  .comments {
    .comments-body {
      display: flex;
      flex-direction: column;
    }
  }

  header {
    position: fixed;
    z-index: 999999;
    background: black;
      &.defHeader{
        transition: all 1500ms ease;
        &:before{
          background: @blackMainColor;
        }
      }
    .header-body {
      height: 70px;
      position: relative;
      .top-social{
        display: none;
        img{
          max-width: 30px
        }
      }
      .social-container {
        display: none;
      }

      .lang-container {
        order: 1;
        z-index: 4;
      }

      .header-burger {
        display: block;
        position: relative;
        width: 30px;
        height: 20px;
        z-index: 4;
        order: 3;

        &.active {
          &:before {
            transform: rotate(45deg);
            top: 9px;
          }

          &:after {
            transform: rotate(-45deg);
            bottom: 9px;
          }

          span {
            transform: scale(0);
          }
        }

        span {
          background-color: @whiteColor;
          position: absolute;
          width: 100%;
          height: 2px;
          left: 0;
          top: 9px;
          transition: all 0.3s ease 0s;
        }

        &:before, &:after {
          content: '';
          background-color: @whiteColor;
          position: absolute;
          width: 100%;
          height: 2px;
          left: 0;
          transition: all 0.3s ease 0s;
        }

        &:before {
          top: 0;
        }

        &:after {
          bottom: 0;
        }
      }

      .header-nav {
        position: fixed;
        top: -100%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        padding: 115px 15px 20px 15px;
        transition: all 0.3s ease 0s;
        overflow: auto;

        &.active {
          top: 0;
        }

        .header-list {
          display: flex;
          flex-direction: column;
          padding-left: 0;
          li {
            display: flex;
            justify-content: center;
            width: 100%;
            border: 1px solid #f3c644;
            margin-top: 15px;
            &.nav-bouquet{
              order: 1;
            }
            &.nav-nolimits{
              order: 2;
            }
            &.nav-firebird{
              order: 3;
            }
            &.top-phone{
              order: 4;
            }
            &.top-address{
              order: 5;
              text-align: center;
            }
            .header-link {
              width: 100%;
              display: block;
              height: 100%;
              padding: 25px;
            }
          }
        }
      }
      .header-logo{
        flex: 0 0 80px;
        order:2;
      }
    }
  }

  .about{
    .about-body {
      display: block;
      margin-top: 50px;
      .mobileH2{
        display: block;
      }
      .descH2{
        display: none;
      }
      .body-item{
        &.all-sound{
          display: flex;
          flex-direction: column;
          .about-spoon{
            h2{
              display: block;
            }
          }
        }
        &.spoon-char-body{
          display: flex;
          flex-direction: column;
          .spoon-char{
            order:2;
          }
          .construction{
            order:4;
          }
          .consth2{
            order: 3;
          }
          h2.char{
            order:1;
            font-size: 2rem;
          }
          .about-spoon{
            order:3;
          }
        }
        &.spoon3d{
          display: flex;
          align-items: center;
          justify-content: center;
          .spoon-item{
            max-width: 200px;
          }
        }
      }
      .done-info {
        display: block;
        margin-top: 25px;
        .item{
          margin-top: 15px;
        }
      }
    }
  }

  .prod-page{

  }

  .banner-top{
    margin-top: 70px;
    .banner-item{
      &.bouquet-page{
        picture{
          display: none;
        }
      }
    }
  }

  footer {
    .footerCopy{
    .copy-item {
      display: block;
      .item{
        flex: 1 0 100%;
        &.copyright{
          font-size: 1.3rem;
          text-align: center;
          opacity: 1;
        }
        &.author{
          padding: 10px 0;
          font-size: 1rem;
          text-align: center;
        }
      }
    }
    }
  }

  .narrow{
    .narrow-body{
      .narrow-items{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .designed-block{
          strong{
            font-weight: 900;
            font-size: 2.2rem;
          }
          ul{
            padding-left: 15px;
            margin-top: 10px;
            list-style: disc;
            color: @redcolor;
            li{
              padding: 4px 0;
              span{
                color:@blackMainColor;
                font-size: 1.8rem;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  .dual{
    .dual-body{
      .dual-items{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .designed-block{
          strong{
            font-weight: 900;
            font-size: 2.2rem;
          }
          ul{
            padding-left: 15px;
            margin-top: 10px;
            list-style: disc;
            color: @redcolor;
            li{
              padding: 4px 0;
              span{
                color:@blackMainColor;
                font-size: 1.8rem;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  .contact-page{
    margin: 100px 0 50px 0;
    .contacts-wrapper{
      display:flex;
      flex-wrap: wrap;
      .left-block{
        flex: 0 1 100%;
        margin-top: 15px;
      }
      .right-block{
        flex: 0 1 100%;
        padding: 15px 10px;
        .fback-body {
          max-width: 200px;
          h2{
            padding: 10px 0 5px;
          }
        }
        .for{
          margin-bottom: 5px;
        }
      }
    }
  }
}


