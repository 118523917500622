
@blackMainColor: #202021; /*49 42 32*/
@greenColor: #00791a;
@greenColorOpacity: rgba(0, 121, 26, .8);
@yellowColor: #f3c644;
@redcolor: #d44d60;
@lightBlueColor: #a1d5f8;
@whiteColor: #ffffff;
@blackColor: #000000;
@blueColor: #3c4a62;

@h1Size: 3rem;
@h2Size: 2.5rem;
@h3Size: 2.25rem;
@h3Size: 2rem;
@h5Size: 1.875rem;
@pSize: 1.5rem;

@font-face {
  font-family: "FontAwesome";
  src: url("assets/webfonts/fa-brands-400.eot") format("embedded-opentype"), /* IE6-IE8 */ url("assets/webfonts/FontAwesome.otf") format("opentype"), /* Legacy iOS */ url("assets/webfonts/fa-brands-400.svg") format("svg"), /* Legacy iOS */ url("assets/webfonts/fa-brands-400.ttf") format("truetype"), /* Safari, Android, iOS */ url("assets/webfonts/fa-brands-400.woff") format("woff"), /* Modern Browsers */ url("assets/webfonts/fa-brands-400.woff2") format("woff2"); /* Modern Browsers */
}



/* MAIN STYLES ------------------------------------------------------------------------------------------------------ */

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  /*height: 100%;*/
  font-family: 'Roboto', serif;
  min-width: 320px;
  font-style: normal;
  font-weight: normal;

}

html {
  font-size: 62.5%;
}

body {
  font-size: 16px;
  font-size: 1.6rem;
  background: #000;
}

*, ::after, ::before{
  box-sizing: border-box;
}

/*body.opened-model{
  overflow-y: hidden;
}*/

img {
  max-width: 100%;
  height: auto;
}

button:active, button:focus {
  outline: none !important;
}
button::-moz-focus-inner {
  border: 0 !important;;
}

/*.wrapper2 {
  width: 100%;
  margin: 0 auto;
}*/

.wrapper-width {
  min-width: 320px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.wrapper-container{
  padding: 0 15px;
}

.content {
  min-height: calc(100% - 354px);

}

h2{
  text-align: center;
  margin-bottom: 10px;
  color: wheat;
}

.sub-header {
  .centered-block {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    h1 {
      color: @whiteColor;
      width: 100%;
      background: white;
      padding: 15px 0;
    }
  }

  .finance {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
}

section {
  display: block;
}

h1 {
  font-size: @h1Size;
  font-family: 'Roboto', serif;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

h2 {
  font-size: @h2Size;
  font-family: 'Roboto', serif;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

h5 {
  font-size: @h5Size;
  font-family: 'Roboto', serif;
  line-height: 30px;
}

p {
  font-size: @pSize;
  font-family: 'Roboto', serif;
  line-height: 24px;
}

.two-block {
  flex: 0 0 50%;
  padding: 0 15px;
}

.three-block {
  flex: 0 1 33.333%;
  padding: 0 15px;
}

.four-block{
  flex: 0 1 25%;
  padding: 0 15px;
}

/*************** HEADER ***************/
header{
  /*position: absolute;*/
  top:0;
  left:0;
  z-index: 100;
  color:wheat;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100px;
    z-index: 3;
    border-bottom: 2px solid @yellowColor;
  }
  &.header{
    &:before{
      background: rgba(0,0,0,1);
    }
  }

  &.defHeader{
    transition: all 1500ms ease;
    &:before{
      background: rgba(0,0,0,.4);
    }
  }
  .top-social{
    display: flex;
    img{
      margin: 0 5px;
      max-width: 40px;
      border: 3px solid transparent;
      border-radius: 50%;
      transition: all 300ms ease;
      &:hover{
        border: 3px solid @yellowColor;
      }
    }
  }
  .header-address{
    font-size: 3.5rem;
  }
  /*.social-container{
    position: fixed;
    top: 120px;
    right: 0;
    z-index: 5;
    li{
      a.social-item{
        text-decoration: none;
        color:@whiteColor;
        display: block;
        border-radius: 15px 0 0 15px;
        margin: 5px 0;
        padding: 5px 20px 5px 10px;
        img{
          transition: all 1s ease;
          display: block;
          height: 35px;
        }
        &:hover{
          !*padding: 5px 50px 5px 10px;*!
          img{
            transform:scale(1.3);
          }
        }
      }
    }
  }*/
  .header-body{
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
    z-index: 5;
    .lang-container{
      display:flex;
      order: 3;
      z-index: 4;
      .lang-wrapper{
        display: flex;
        align-items: center;
        .lang-btn{
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          width: 41px;
          height: 41px;
          text-align: center;
          border-radius: 50%;
          /*padding-top: 10px;*/
          border: 3px solid @whiteColor;
          color: @whiteColor;
          transition: all 0.3s ease 0s;
          text-transform: uppercase;
          font-weight: 900;
          &:hover{
            color: @blackMainColor;
            border: 7px solid @whiteColor;
            background: @whiteColor;
          }
        }
      }
    }
/*    .header-burger{
      display: none;
    }*/


    a.header-logo{
        position: relative;
        z-index: 4;
        flex: 0 0 120px;
        display:flex;
        align-content: center;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        text-decoration: none;
      picture{
        width: 120px;
      }
        span{
          color:white;
          font-size: 2.5rem;
        }
        img{
          max-width: 100%;
          display: block;
          max-height: 90px;
        }
      }


    .header-list{
      display: flex;
      position: relative;
      z-index: 3;
      li {
        &.top-phone{
          line-height: 21px;
        }
        list-style: none;
        padding: 0 25px;
        a.header-link {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          text-decoration: none;
          text-transform: uppercase;
          color:@whiteColor;
          padding: 33px 15px;
          font-size: 1.5rem;
          transition-duration: .5s;
          text-align: center;
          &:hover{
            color: @yellowColor;
          }
        }
      }
    }
  }
}
.shadowed {
  -webkit-box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 0.5)
}
section {
  display: block;
}
.header-nav {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: black;
  padding: 15px 15px 20px 15px;
  transition: all 0.3s ease 0s;
  overflow: hidden;
  border-bottom:2px solid #f3c644;
  &.active {
    top: 0;
  }

  .header-list {
    display: flex;
    padding-left: 140px;
    margin-top: -5px;
    justify-content: center;
    align-items: center;
    li {
      margin: 0;
      .header-link {
        padding: 15px;
        height: auto;
        justify-content: left;
      }
    }
  }
}
.header-burger {
  display: block;
  position: relative;
  width: 30px;
  height: 20px;
  z-index: 4;
  order: 3;

  &.active {
    &:before {
      transform: rotate(45deg);
      top: 9px;
    }

    &:after {
      transform: rotate(-45deg);
      bottom: 9px;
    }

    span {
      transform: scale(0);
    }
  }

  span {
    background-color: @whiteColor;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 9px;
    transition: all 0.3s ease 0s;
  }

  &:before, &:after {
    content: '';
    background-color: @whiteColor;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    transition: all 0.3s ease 0s;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
}

.banner-top {
  position: relative;
  z-index: 1;
/*  margin-top: -66px;*/

  .banner-main {
    /*background-image: url('assets/images/bg.jpg');
    background-repeat: no-repeat;*/
    .banner-body{
      /*max-height: 940px;*/
      max-height: 100%;
      overflow: hidden;
      .banner-item{
        img{
          width: 100%;
        }
      }
    }
    .banner-block {
      z-index: 9999;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: @whiteColor;


      h1 {
        font-size: @h1Size;
      }

      h5 {
        font-size: @h5Size;
      }
    }
  }
}

.about {
  color:white;
  p {
    text-align: justify;
  }
  a{color: wheat}
  .about-body{
    display: flex;
    margin-top: 50px;
    .mobileH2{
      display: none;
    }
    .descH2{
      display: block;
    }
    .body-item{
      text-align: justify;
        &.all-sound{
          display: flex;
          .about-spoon{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: end;
            picture{max-width: 70%}
            &.firebird{
              picture{max-width: 50%}
            }
          }

        }
      .spoon-char{
        display: flex;
        justify-content: center;
        .left{
          ul{
            list-style: disc;
            padding: 5px 0 5px 25px;
          }
        }
        .right{
          ul{
            list-style: none;
            color:wheat;
            padding: 5px 0 5px 10px;
          }
        }
      }
    }
  .done-info{
    display:flex;
    margin-top: 25px;
    .item{
      display:flex;
      flex-direction: column;
      background:@whiteColor;
      padding: 50px 0;
      margin: 0 10px;
      border: 3px solid @blueColor;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      text-decoration: none;
      transition: all 1s ease;
      border-radius: 15px;
      span{
        text-align: center;
        padding-top: 15px;
        font-weight: 700;
        color:@blueColor;
        transition: all 1s ease;
      }
      &:hover{
        background: @blueColor;

        span{
          color:@whiteColor;
        }
      }
    }
  }
  }
  .counter-block{
    margin: 25px 0;

    .block{
      border: 3px dashed @greenColor;
      margin: 15px 0;
      padding: 15px 0;
    }
  }
}



.brands{
  background-color: @whiteColor;
  padding: 50px 0;
  h2{
    font-weight: bold;
    text-transform: uppercase;
  }
}

table.platforms{
  width: 100%;
  thead{
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    background: @greenColor;
    color: @whiteColor;
  }
  tr{
    &:nth-child(even){
      background: @greenColorOpacity;
      color: @whiteColor;

    }
    td{
      &:nth-child(1){
        text-align: center;
      }
      padding: 15px 10px;
      border: 2px solid @greenColor;
    }
  }
}
.prod-page{
  h1{
    color:@blueColor;
  }
}
.all-prods-page{
  margin-top: 150px;
  position: relative;
  .products{
    display: flex;
    flex-wrap: wrap;
    .prod-block{
      margin-top: 30px;
      .card{
        position: relative;
        .card-body{
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}



.parksPage{
  a{

    &:hover{
      .parksBlock{
        h2{
          background:@greenColor;

          bottom:25px;
        }
        span{
          display: block;

        }
      }
    }
    .parksBlock{
      position: relative;
      h2{
        transition: all .5s ease;
        position: absolute;
        bottom:0;
        width: 100%;
        margin-bottom: 0px;
        text-align: center;
        background: @greenColorOpacity;
        padding: 5px 0;
        color:@whiteColor;
      }
      span{
        width: 100%;
        text-align: center;
        position: absolute;
        bottom:0;
        display: none;
      }
    }
  }

}

.form-control {
  margin: 5px;
}

.overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;

  .alert-success {
    color: @whiteColor;
  }

  #feedbackForm .btn-send .btn {
    color: @whiteColor;
  }

  .modal-window {
    width: 300px;
    padding: 20px;
    text-align: center;
    position: fixed;
    top: 100px;
    left: 50%;
    z-index: 1000;
    margin-left: -150px;
    display: none;
  }

  #callbackForm_ajax div {
    color: @whiteColor;
    background: #dc3545;
  }

  .control-label {
    color: @whiteColor;
  }
}

.fade:not(.show) {
  opacity: 1 !important;
}


footer {
  position: relative;
  color: @whiteColor;
  background-color: #000;
  .four-block{
    picture{
      max-width: 60%;
    }
  }
  .footer-list{
    display: flex;
    position: relative;
    z-index: 3;
    justify-content: space-between;
    padding: 41px 0;

    li {
      list-style: none;
      .footer-link {
        text-decoration: none;
        text-transform: uppercase;
        color:@whiteColor;
        padding: 41px 0;
        font-size: 18px;
        &:hover {
          color: @yellowColor;
        }
      }
    }
  }

  a {
    color: @whiteColor;
    font-size: @pSize;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    -webkit-transition-duration: .5s;
    -moz-transition-duration: .5s;
    -ms-transition-duration: .5s;
    -o-transition-duration: .5s;
    transition-duration: .5s;
    text-decoration: none;


  }
.footer-address{
  padding: 15px 0;
  display: flex;
  flex-wrap: wrap;
  .four-block{
    line-height: 20px;
  }
    .block{
      padding: 15px;
      border-radius: 25px;
      color: @whiteColor;
      .position-right{
        b{
          display: block;
          color:@yellowColor;
          text-align: right !important;
        }
      }
      img{
        display: block;
        text-align: center;
      }

    }

}
  .footerCopy {
    border-top: 5px solid @yellowColor;
    .copy-item{
      display: flex;
      justify-content: space-between;
      .item{
        opacity: .3;
        font-size: 1rem;
      }
    }
  }

  li {
    float: left;
    list-style: none;
  }
}

.comments{
  .comments-body{
    display: flex;
      .block{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: @greenColorOpacity;
        padding: 15px;
        border-radius: 25px;
        color: @whiteColor;
        .position-right{
          b{
            display: block;
            color:@yellowColor;
            text-align: right !important;
          }
        }
        img{
          display: block;
          text-align: center;
        }

      }
  }
}

.special-propose{}
  .propose-block{
    display: flex;
    z-index: 999999999;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    text-align: center;
    padding: 15px 0;
    background: @blueColor;
    width: 100%;
    border-top:2px solid #f3c644;
    h3{
      font-size: 2rem;
      padding: 0 10px;
      color: @yellowColor;
    }
    p{
      padding: 0 10px;
      color:@whiteColor;
    }
    a{
      border: 2px solid white;
      background: transparent;
      padding: 5px 10px;
      color:@whiteColor;
      text-decoration: none;
    }
  }
  .propose-block-static{
    display: flex;
    z-index: 999999999;
    justify-content: center;
    align-items: center;
    position: absolute;
    left:0;
    bottom: 0;
    text-align: center;
    padding: 15px 0;
    width: 100%;
    border-top:2px solid #f3c644;
    h3{
      font-size: 2rem;
      padding: 0 10px;
      color: @yellowColor;
    }
    p{
      padding: 0 10px;
      color:@whiteColor;
    }
    a{
      background: transparent;
      padding: 5px 10px;
      color:@whiteColor;
      text-decoration: none;
    }
  }
.special-propose-static{
  display: none;
}
.stat-active{
  display: block;
}

.partner{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  img{
    width: 300px;
    margin: 0 25px;
  }
  h2{
    margin-top: 25px;
  }
}

.narrow{
  padding: 50px 0;
  .narrow-body{
    margin-top: 20px;
    .narrow-items{
      display: flex;
      flex-direction: row;
      align-items: center;
      &.narrow-dual{
        justify-content: center;
        align-items: center;
      }
      .designed-block{
        strong{
          font-weight: 900;
          font-size: 2.2rem;
          color:@blueColor;
        }
        ul{
          margin-top: 10px;
          list-style: disc;
          color: @redcolor;
          li{
            padding: 10px 0;
            span{
              color:@blackMainColor;
              font-size: 1.8rem;
            }
          }
        }
        p{
          font-size: 1.8rem;
          margin-top: 15px;
          span{
            color: @redcolor;
          }
        }
      }
    }
  }
}

.dual{
  padding: 50px 0;
  .dual-body{
    margin-top: 20px;
    .dual-items{
      display: flex;
      flex-direction: row;
      align-items: center;
      &.dual-dual{
        justify-content: center;
        align-items: center;
      }
      .designed-block{
        strong{
          font-weight: 900;
          font-size: 2.2rem;
          color:@blueColor;
        }
        ul{
          margin-top: 10px;
          list-style: disc;
          color: @redcolor;
          li{
            padding: 2px 0;
            span{
              color:@blackMainColor;
              font-size: 1.8rem;
            }
          }
        }
        p{
          font-size: 1.8rem;
          margin-top: 15px;
          span{
            color: @redcolor;
          }
        }
      }
    }
  }
}

.articles-list{
  .articles-body{
    display:flex;
    flex-wrap: wrap;
    .articles-items{
      h5{
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        margin-right: -50%;
        width: 100%;
        color: white;
        background: rgba(60, 74, 98, .9);
        padding: 5px 0;
        transform: translate(-50%, -50%);
      }
      .more-bottom{
        a {
          text-decoration: none;
          color:@blueColor;
        }
      }
    }
  }
}

.article-page{
  h1{
    padding: 25px 0;
  }
}

.contact-page{
  margin: 150px 0 50px 0;
  .contacts-wrapper{
    display:flex;
    .left-block{
      flex: 1 0 50%;
      .adress-block{
        display:flex;
        flex-direction: column;
        padding: 0 25px;
        .adress-item{
          margin: 10px 0;
          i{
            color: @blueColor;
            width: 30px;
            font-size: 2rem;
            text-align: center;
            &.fab{
              font-size: 3rem;
            }
          }
        }

      }
    }
    .right-block{
      flex: 1 0 50%;
      padding: 50px 25px;
      color:@whiteColor;
      background: @blueColor !important;
      border-radius: 25px;
      .for{
        text-align: center;
        margin-bottom: 25px;
        line-height: 24px;
      }
      .fback-body{
        h2{
          color:@whiteColor;
          text-align: center;
          font-size: 2rem;
          padding: 30px 0 20px 0;
        }

        max-width: 300px;
        margin: 0 auto;
        color:@whiteColor;
        padding: 10px;

        border-radius: 10px;
        .form-horizontal{

          .column-left{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            input{
              width: 280px;
              padding: 5px;
              background: rgba(255,255,255, 1);
              border-radius: 5px;
              border: 2px solid @whiteColor;

            }
            label{
              padding-left: 5px;
            }
            textarea{
              width: 280px;
              padding: 5px;
              background: rgba(255,255,255, 1);
              border-radius: 10px;
              border: 2px solid @whiteColor;
              min-height: 70px;
            }
          }
          .column-right{
            display: flex;
            justify-content: center;
            button{
              &.btn{
                margin-top: 15px;
                padding: 5px;
                border-radius: 5px;
                border: 2px solid @whiteColor;
                color: @whiteColor;
                font-weight: 700;
                text-transform: uppercase;
                background: @blueColor;
              }
            }
          }
        }

      }
    }
  }
}